<!-- 项目配置弹窗 -->
<template>
  <div>
    <el-dialog
        width="40%"
        center
        :visible="visible"
        :lock-scroll="false"
        :destroy-on-close="true"
        custom-class="ele-dialog-form"
        :title="isUpdate?'项目配置':'项目配置'"
        @update:visible="updateVisible">
      <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="140px">
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="服务项目" label-width="100px" prop="items">
              <el-select
                  :disabled="disabled"
                  clearable
                  v-model="form.items"
                  placeholder="请选择"
                  class="ele-fluid">
                <el-option v-for="(item) in ServicesAvailable" :label="item.label" :value="item.serial_number"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目属性" prop="items_attribute">
              <el-select
                  clearable
                  v-model="form.items_attribute"
                  placeholder="请选择"
                  class="ele-fluid">
                <el-option
                    v-for="(item) in dict.type.detailproperty"
                    :label="item.label"
                    :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="客户名称" label-width="100px" prop="channel_id">
              <el-select
                  clearable
                  v-model="form.channel_id"
                  placeholder="请选择"
                  class="ele-fluid">
                <el-option v-for="(item) in client" :label="item.channel_name + item.channel_type" :value="parseInt(item.id)"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="C端展示" prop="is_C">
              <el-select
                  clearable
                  v-model="form.is_C"
                  placeholder="请选择"
                  class="ele-fluid">
                <el-option
                    v-for="(item) in dict.type.is_C"
                    :label="item.label"
                    :value="parseInt(item.value)"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <div style="display: flex;justify-content: space-between;">
            <div style="display: flex;align-items: center;">
              <span style="width: 5px;height: 16px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
              <span style="font-size: 18px;font-weight: 700;color: #3f4155;">默认拍照模板</span>
            </div>
            <div>
              <el-button style="background: #3F4157!important;border-color: #3F4157!important;color: #FFFFFF!important;" @click="add()">
                <i class="el-icon-tianjia" />
                <span style="vertical-align: middle">添加拍照模板</span>
              </el-button>
            </div>
          </div>
        </el-row>

        <div style="margin-top: 20px;background: #f7f8fa;border: 1px solid #e9eaf1;border-radius: 6px;padding: 20px 20px 0 20px;">
          <el-form-item label="救援地拍照模板：" prop="">
            <el-select
                clearable
                v-model="form.rescue_template_id"
                placeholder="请选择"
                class="ele-fluid">
              <el-option v-for="(item) in template" :label="item.template_name" :value="item.id"/>
            </el-select>
          </el-form-item>
          <el-form-item label="目的地拍照模板：" prop="" v-if="form.items_attribute == 'double'">
            <el-select
                clearable
                v-model="form.bourn_template_id"
                placeholder="请选择"
                class="ele-fluid">
              <el-option v-for="(item) in template" :label="item.template_name" :value="item.id"/>
            </el-select>
          </el-form-item>
        </div>



      </el-form>
      <div slot="footer">
        <el-button
            @click="updateVisible(false)">取消
        </el-button>
        <el-button
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            @click="save">确定
        </el-button>
      </div>
    </el-dialog>


    <el-dialog
        width="80%"
        center
        :visible="photoTemplate"
        :lock-scroll="false"
        :destroy-on-close="true"
        custom-class="ele-dialog-form"
        :title="isUpdate?'拍照模板':'拍照模板'"
        @update:visible="photoTemplateVisible">

      <el-form
          ref="form1"
          :model="form1"
          :rules="rules1"
          label-width="100px">
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="模板名称：" prop="template_name">
              <el-input
                  placeholder="请输入内容"
                  v-model="form1.template_name"
                  clearable>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="15">
          <el-col :span="12">
            <div style="display: flex;align-items: center;margin-top: 11px;">
              <span style="width: 5px;height: 16px;background: #ff9b05;display:inline-block; margin-right: 10px;"></span>
              <span style="font-size: 18px;font-weight: 700;color: #3f4155;">已添加的示例图</span>
            </div>
            <div style="margin-top: 20px;padding: 20px;background: #f7f8fa;border: 1px solid #e9eaf1;border-radius: 6px;">
              <el-row style="background: #E9ECF1;text-align: center;">
                <el-col :span="4" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                  编号
                </el-col>
                <el-col :span="8" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                  名称
                </el-col>
                <el-col :span="6" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                  示例图
                </el-col>
                <el-col :span="6" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                  操作
                </el-col>
              </el-row>

              <draggable v-model="dataList" group="people" @change="log">
                <template>
                  <el-row style="background: #FFFFFF;text-align: center; cursor: pointer;" v-for="(item,index) in dataList" :key="index">
                    <el-col :span="4" style=" border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;justify-content: center;">
                      <span>{{item.id}}</span>
                    </el-col>
                    <el-col :span="8" style="border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;">
                      <span style="margin-left: 8px;margin-right: 8px;">{{item.sample_graph_name}}</span>
                    </el-col>
                    <el-col :span="6" style="border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;justify-content: center;">
                      <img :src="item.sample_graph_path" alt="" style="width: 50px;height: 45px;">
                    </el-col>
                    <el-col :span="6" style="border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;justify-content: center;">
                      <span style="margin-right: 15px;">拖动排序</span>
                      <el-popconfirm title="确定删除吗？" @confirm="del(item,index)">
                        <span slot="reference">删除</span>
                      </el-popconfirm>
                    </el-col>
                  </el-row>
                </template>
              </draggable>
              <el-empty v-if="dataList.length == 0" description="数据为空"></el-empty>
            </div>
          </el-col>

          <el-col :span="12">
            <el-row :gutter="15" style="display: flex;align-items: center;">
              <el-col :span="6">
                <div style="display: flex;align-items: center;">
                  <span style="width: 5px;height: 16px;background: #ff9b05;display:inline-block; margin-right: 10px;"></span>
                  <span style="font-size: 18px;font-weight: 700;color: #3f4155;">选择示例图</span>
                </div>
              </el-col>
              <el-col :span="10" style="display: flex;">
                <el-input
                    placeholder="请输入内容"
                    v-model="form.input"
                    clearable>
                </el-input>
                <el-button type="primary" icon="el-icon-search">搜索</el-button>
              </el-col>
              <el-col :span="8">
                <el-button style="background: #31334B!important;border-color: #31334B!important;color: #FFFFFF!important;" @click="addimg()">
                  <i class="el-icon-tianjia" />
                  <span style="vertical-align: middle">添加示例图</span>
                </el-button>
              </el-col>
            </el-row>

            <div>
              <div style="margin-top: 20px;padding: 20px;background: #f7f8fa;border: 1px solid #e9eaf1;border-radius: 6px;">
                <el-row style="background: #E9ECF1;text-align: center;">
                  <el-col :span="4" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                    编号
                  </el-col>
                  <el-col :span="8" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                    名称
                  </el-col>
                  <el-col :span="6" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                    示例图
                  </el-col>
                  <el-col :span="6" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                    操作
                  </el-col>
                </el-row>

                <el-row style="background: #FFFFFF;text-align: center; cursor: pointer;" v-for="(item,index) in List" :key="index">
                  <el-col :span="4" style=" border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;justify-content: center;">
                    <span>{{item.id}}</span>
                  </el-col>
                  <el-col :span="8" style="border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;">
                    <span style="margin-left: 8px;margin-right: 8px;">{{item.sample_graph_name}}</span>
                  </el-col>
                  <el-col :span="6" style="border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;justify-content: center;">
                    <img :src="item.sample_graph_path" alt="" style="width: 50px;height: 45px;">
                  </el-col>
                  <el-col :span="6" style="border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;justify-content: center;">
                    <el-popconfirm title="确定添加吗？" @confirm="Addtemplate(item,index)">
                      <span slot="reference" style="color: #FF9B05;">添加到模板</span>
                    </el-popconfirm>
                  </el-col>
                </el-row>
              </div>
              <el-pagination
                  style="text-align: center; margin-top: 20px"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total"
                  :page-size="where.limit"
                  :current-page="where.page"
                  @size-change="ChangeSize"
                  @current-change="currentChange"
                  background>
              </el-pagination>
            </div>
            <el-empty v-if="List.length == 0" description="数据为空"></el-empty>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button
            @click="photoTemplateVisible()">取消
        </el-button>
        <el-button
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            @click="photoTemplateVisiblesave('form1')">确定
        </el-button>
      </div>
    </el-dialog>

    <!--添加示例图-->
    <el-dialog
        width="30%"
        center
        :visible="addSampleImage"
        :lock-scroll="false"
        :destroy-on-close="true"
        custom-class="ele-dialog-form"
        :title="isUpdate?'添加示例图':'添加示例图'"
        @update:visible="addSampleImageVisible">
      <el-form
          ref="form2"
          :model="form2"
          :rules="rules2"
          label-width="100px">
        <el-row :gutter="15">
          <el-col :span="24">
            <el-form-item label="示例图名称" prop="sample_graph_name">
              <el-input
                  placeholder="请输入示例图名称"
                  v-model="form2.sample_graph_name"
                  clearable>
              </el-input>
            </el-form-item>
            <el-form-item label="拍照要求:" prop="claim">
              <el-input
                  placeholder="请输入拍照要求"
                  v-model="form2.claim"
                  clearable>
              </el-input>
            </el-form-item>
            <el-form-item label="拍照图例">
              <div class="upload_bg_shouchi">
                <el-upload
                    :action="action"
                    :headers="headers"
                    :on-success="successUpload"
                    :limit="1"
                    :file-list="fileList"
                    :class="{hide_box: handupload_btn}"
                    :on-change="handchange"
                    list-type="picture-card"
                    :on-preview="handPreview"
                    :on-remove="handhandleRemove">
                  <!--<i class="el-icon-plus"></i>-->
                  <img style="margin-top: 20px;" src="@/assets/images/home/upload_anniu.png" alt="">
                  <div style="font-size: 12px;font-weight: 500;color: #ff9b05;margin-top: 10px;">上传营业执照</div>
                  <div style="font-size: 12px;font-weight: 400;color: #c1c2ce;margin-top: 20px;">支持jpg，jpeg，png格式，最大10M</div>
                </el-upload>
                <el-dialog :visible.sync="handdialogVisible">
                  <img width="100%" :src="handdialogImageUrl" alt="">
                </el-dialog>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button
            @click="addSampleImageVisible()">取消
        </el-button>
        <el-button
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            @click="addSampleImageVisiblesave('form2')">确定
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Config from '@/config'
//拖动排序
import draggable from 'vuedraggable'
// 引入的接口
import {
  Addphoto_templates,
  getsamples,
  Addsamples,
  Editphoto_templates,
  Addservices_config,
  Editservices_config,
  getphoto_template_select
} from "@/api/yunli";
//服务项目接口
import {getget_services_available} from '@/api/yunli'
import {get_select} from "@/api/custom";

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object,
    id:Number
  },
  components: {
    draggable
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      disabled:false,
      rules:{
        items: [
          {required: true, message: '请选择服务项目', trigger: 'change'},
        ],
        items_attribute: [
          {required: true, message: '请选择服务项目属性', trigger: 'change'},
        ],
        channel_id: [
          {required: true, message: '请选择客户', trigger: 'change'},
        ],
        is_C: [
          {required: true, message: '请选择C端是否展示', trigger: 'change'},
        ],
      },

      // 表单数据
      form1: Object.assign({template_name:'',sample:[]}),
      rules1: {
        template_name: [
          {required: true, message: '请输入名称', trigger: 'blur'},
        ],
      },

      // 验证规则
      form2:{},
      rules2: {
        sample_graph_name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        claim: [
          { required: true, message: '请输入拍照要求', trigger: 'blur' },
        ],
      },

      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 已添加的示例图
      dataList:[],
      //拍照模板弹窗
      photoTemplate:false,


      // 添加示例图弹窗
      addSampleImage:false,
      //营业执照照片
      handdialogImageUrl:'',
      handdialogVisible:false,
      handupload_btn:false,
      fileList:[],

      // 上传接口
      action: setting.apiBaseURL + 'common/upload/capacity',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },

      //选择示例图
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 总条目数
      total: 0,
      //已添加的示例图数组
      List:[],

      //服务项目
      ServicesAvailable:[],
      //客户列表
      client:[],
      //模板下拉
      template:[],

    };
  },
  mounted() {
    //判断是新增还是编辑
    if (this.data) {
      console.log(this.data)
      this.form = Object.assign({}, this.data);
      this.isUpdate = true;
      this.disabled = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }

    // 获取服务项目
    this.getFuwuxiangmu();
    // 获取客户
    this.getKehu();
    // 获取模板下拉
    this.getMoban();

    // 获取选择示例图列表
    this.getList();
  },
  methods: {
    //获取服务项目
    getFuwuxiangmu(){
      getget_services_available().then(res => {
        this.ServicesAvailable = res.data;
      })
    },

    //获取客户名称列表
    getKehu(){

      get_select().then(res => {
        this.client = res.data;
      })
    },

    // 获取拍照模板
    getMoban(){
      getphoto_template_select(this.id).then(res => {
        this.template = res.data;
      })
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        console.log(this.form);
        if (valid) {
          this.loading = true;
          //判断是新增还是修改
          if(this.isUpdate === false){
            Addservices_config(this.id,this.form).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }else {
            Editservices_config(this.id,this.data.id,this.form).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


    // 选择示例图事件
    // 获取选择示例图列表
    getList(){
      getsamples(this.id,this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
      }).catch((res) => {
        this.$Message.error(res.msg);
      })
    },
    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },
    //点击添加到模板
    Addtemplate(item,index){
      this.dataList.push(item);
      console.log(this.dataList);
    },
    //点击添加照片模板
    add(){
      //显示添加照片模板弹窗
      this.photoTemplate = true;
    },
    //关闭添加照片模板弹窗
    photoTemplateVisible(){
      this.photoTemplate = false;
      this.updateVisible(true);
    },
    photoTemplateVisiblesave(){
      this.$refs['form1'].validate((valid) => {
        console.log(this.form1);
        if (valid) {
          this.loading = true;
          this.dataList.forEach(item => {
            this.form1.sample.push(item.id);
          })
          Addphoto_templates(this.id,this.form1).then(res => {
            console.log(res)
            this.loading = false;
            if (res.code === 200) {
              this.$message.success(res.msg);
              if (!this.isUpdate) {
                this.form1 = {};
              }
              this.updateVisible(true);
              this.photoTemplate = false;
            } else {
              this.$message.error(res.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.msg);
          })
        } else {
          return false;
        }
      });
    },

    // 拖动排序事件
    log: function(evt) {
      window.console.log(evt);
    },

    // 删除
    del(item){
      console.log(item);
      // this.$utils.confirm("确定要删除吗？", function () {
      //   // 执行一些需要的逻辑
      //   console.log('点击确定')
      // }, "确定", 'warning');
      console.log('点击删除')
    },

    // 点击添加示例图
    addimg(){
      //显示添加示例图
      this.addSampleImage = true;
      //隐藏添加照片模板
      this.photoTemplate = false;
      //隐藏项目配置弹窗
      // this.updateVisible(false);

      // 示例图 图片相关
      this.handdialogImageUrl = '';
      this.handdialogVisible = false;
      this.handupload_btn = false;
      this.fileList = [];
    },
    // 关闭添加示例图弹窗
    addSampleImageVisible(){
      this.addSampleImage = false;
      this.photoTemplate = true;
      // this.updateVisible(false);
    },
    // 确定添加
    addSampleImageVisiblesave(){
      console.log(this.form1);
      this.$refs['form2'].validate((valid) => {
        if (valid) {
          this.loading = true;
          Addsamples(this.id,this.form2).then(res => {
            console.log(res)
            this.loading = false;
            if (res.code === 200) {
              this.$message.success(res.msg);
              if (!this.isUpdate) {
                this.form2 = {};
              }
              this.photoTemplate = true;
              this.addSampleImage = false;
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.msg);
          })
          return false;
        }
      });
    },

    //营业执照照片事件
    handchange(){
      this.handupload_btn = true;
    },
    handhandleRemove(){
      this.handupload_btn = false;
    },
    handPreview(file){
      this.handdialogImageUrl = file.url;
      this.handdialogVisible = true;
    },
    //上传成功
    successUpload(file,fileList){
      console.log(file);
      console.log(fileList)
      this.handdialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form2.sample_graph_path = file.data.urls;
    },

  }
}
</script>

<style lang="scss" scoped>
.hide_box /deep/ .el-upload--picture-card {
  display: none;
}
</style>
